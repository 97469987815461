import Weather from "./components/weather";
import Footer from "./components/Footer";
// import "./App.css";

function App() {
  return(
    <div>
      <Weather />
      <Footer />
    </div>
    
  );
}

export default App;
